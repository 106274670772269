import "./main.scss"

const smoothScroll = require("smoothscroll")
const autosize = require("autosize")
import Sortable, { AutoScroll } from "sortablejs/modular/sortable.core.esm.js"

import { initClearableImageInput } from "./js/clearable-image-input"
import { initOrderToggle } from "./js/order"
import { breakless } from "./js/utils/breakless"
import { printPage } from "./js/utils/print-page"

window.Modernizr = {
  csstransitions: true, // All supported browsers support CSS transitions
}

require("script-loader!jquery/dist/jquery.min")
require("script-loader!foundation-sites/js/foundation/foundation")
require("script-loader!foundation-sites/js/foundation/foundation.topbar")
require("script-loader!foundation-sites/js/foundation/foundation.alert")
require("script-loader!foundation-sites/js/foundation/foundation.reveal")
require("script-loader!timeago")
require("script-loader!timeago/locales/jquery.timeago.de")

window.jQuery(($) => {
  $("abbr.timeago").timeago()
  autosize($("textarea"))

  // Necessary for some idiotic reason.
  window.Foundation.global.namespace = ""

  $(document).foundation({
    namespace: "",
    topbar: {
      // Set this to false and it will pull the top level link name as the back text
      custom_back_text: false,
      // will copy parent links into dropdowns for mobile navigation
      mobile_show_parent_link: false,
      sticky_on: "large",
    },
  })

  $(".back-to-top").click(() => smoothScroll(0, 0))

  $("#id_cover_color input").each(function () {
    $("<span>").addClass(this.value).insertAfter(this)
  })

  $("form[data-maxlength-labels]")
    .find("[maxlength]")
    .each(function () {
      const $t = $(this)
      let span

      if ($t.is("textarea")) {
        $t.prev().append(" <small>Noch <span></span> Zeichen</small>")
        span = $t.prev().find("span")
      } else if ($t.is("input")) {
        $t.before(" <small>Noch <span></span> Zeichen</small>")
        span = $t.siblings("small").find("span")
      }

      $t.on("input", (e) =>
        span.text(
          Number(e.target.getAttribute("maxlength")) - this.value.length,
        ),
      ).trigger("input")
    })

  $("form[data-cover-text]").each(function () {
    const author = document.getElementById("id_author")
    const title = document.getElementById("id_title")

    const warning = $(
      '<div class="alert-box warning" style="display: none;">Achtung: Autorenname und Buchtitel dürfen zusammen eine maximale Länge von 60 Zeichen (inkl. Leerschläge) nicht überschreiten. Wenn Sie Versalschrift (GROSSBUCHSTABEN) verwenden, bleiben Sie bitte unter 45 Zeichen. Grund: Autorenname und Buchtitel werden auf dem Buchrücken angezeigt.</div>',
    )

    warning.prependTo(this)

    function checkLength() {
      const authorLength = author.value.length
      const titleLength = title.value.length

      author.setAttribute("maxlength", 60 - titleLength)
      title.setAttribute("maxlength", 60 - authorLength)

      author.previousSibling.querySelector("span").textContent = Number(
        60 - titleLength - authorLength,
      )
      title.previousSibling.querySelector("span").textContent = Number(
        60 - authorLength - titleLength,
      )
      if (authorLength + titleLength >= 45) {
        warning.fadeIn()
      } else {
        warning.fadeOut()
      }
    }

    author.addEventListener("input", checkLength)
    title.addEventListener("input", checkLength)
    checkLength()
  })

  const formErrors = $("form.order-form .errorlist, form.order-form .error")
  if (formErrors && formErrors.length > 0) {
    smoothScroll(formErrors[0])
  }

  for (const el of document.querySelectorAll(
    "textarea#id_blurb, textarea#id_dedication_text",
  )) {
    breakless(el)
  }

  initClearableImageInput()
})

window.jQuery(($) => {
  const element = document.getElementById("sortable-items")

  if (element) {
    Sortable.mount(new AutoScroll())
    const sortable = Sortable.create(element, {
      handle: ".sortable-handle",
      animation: 150,
      onSort: () => {
        $.ajax({
          url: "order/",
          type: "POST",
          headers: {
            "X-CSRFToken": document.cookie.match(/\bcsrftoken=(\w+?)\b/)[1],
          },
          data: { "sortable-items": sortable.toArray() },
          complete: (data) => {
            if (!data.success) {
              window.location.reload()
            }
          },
        })
      },
    })
  }
})

window.jQuery(() => {
  const element = document.getElementById("editable")
  const contentInput = document.getElementById("id_content")

  if (element && contentInput) {
    import(/* webpackChunkName: "editor" */ "./js/editor").then((editor) => {
      editor.activate()
    })
  }
})

window.jQuery(($) => {
  $("[data-auto-reveal]").foundation("reveal", "open")
  // $(".book-design-form textarea").attr("rows", 2).css("height", "unset")
})

window.jQuery(($) => {
  $(".print").click((e) => {
    const url = e.target.dataset.url
    printPage(url)
  })
})

window.jQuery(() => {
  initOrderToggle()
})
